<template>
  <div v-if="isPermission">
    <CRow>
      <CCol md="12">
        <input id="timezoneText" type="text" v-model="timezone.text" disabled class="form-control" />
        <!-- <div v-if="isEditTimeZone == true">
          <multiselect :placeholder="$t('chooseTimezone')" label="text" :options="options" :searchable="true"
            @input="changeLocale" v-model="timezone" :select-label="$t('pressEnterSelect')"
            :selected-label="$t('selected')" :deselect-label="$t('pressEnterRemove')" disabled >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title" style="font-size: 16px;">
                  {{ props.option.text }}
                </span>
              </div>
            </template>
          </multiselect>
        </div>
        <div v-else>
          <multiselect :placeholder="$t('chooseTimezone')" label="text" :options="options" :searchable="true"
          :select-label="$t('pressEnterSelect')"
            :selected-label="$t('selected')" :deselect-label="$t('pressEnterRemove')"
            v-model="timezone" disabled>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">
                  {{ props.option.text }}
                </span>
              </div>
            </template>
          </multiselect>
        </div> -->
      </CCol>
    </CRow>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import permis from '@/util/permission'
import moment from 'moment';


export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      options: [
        {
          value: 'Etc/GMT+12',
          text: this.$i18n.t('gmtLinewest'),
        },
        { value: 'Pacific/Midway', text: this.$i18n.t('gmtMidway') },
        { value: 'Pacific/Honolulu', text: this.$i18n.t('gmtHonolulu') },
        { value: 'US/Alaska', text: this.$i18n.t('gmtAlaska') },
        {
          value: 'America/Los_Angeles',
          text: this.$i18n.t('gmtLos_Angeles'),
        },
        {
          value: 'America/Tijuana',
          text: this.$i18n.t('gmtTijuana'),
        },
        { value: 'US/Arizona', text: this.$i18n.t('gmtArizona') },
        {
          value: 'America/Chihuahua',
          text: this.$i18n.t('gmtChihuahua'),
        },
        {
          value: 'US/Mountain',
          text: this.$i18n.t('gmtMoutain'),
        },
        { value: 'America/Managua', text: this.$i18n.t('gmtManagua') },
        { value: 'US/Central', text: this.$i18n.t('gmtCentral') },
        {
          value: 'America/Mexico_City',
          text: this.$i18n.t('gmtMexico'),
        },
        { value: 'Canada/Saskatchewan', text: this.$i18n.t('gmtSaskatchewan') },
        {
          value: 'America/Bogota',
          text: this.$i18n.t('gmtBogota'),
        },
        { value: 'US/Eastern', text: this.$i18n.t('gmtEastern') },
        { value: 'US/East-Indiana', text: this.$i18n.t('gmtIndiana') },
        {
          value: 'Canada/Atlantic',
          text: this.$i18n.t('gmtAtlantic'),
        },
        { value: 'America/Caracas', text: this.$i18n.t('gmtCaracas') },
        { value: 'America/Manaus', text: this.$i18n.t('gmtManaus') },
        { value: 'America/Santiago', text: this.$i18n.t('gmtSantiago') },
        { value: 'Canada/Newfoundland', text: this.$i18n.t('gmtNewfoundland') },
        { value: 'America/Sao_Paulo', text: this.$i18n.t('gmtSao_Paulo') },
        {
          value: 'America/Argentina/Buenos_Aires',
          text: this.$i18n.t('gmtBuenos_Aires'),
        },
        { value: 'America/Godthab', text: this.$i18n.t('gmtGodthab') },
        { value: 'America/Montevideo', text: this.$i18n.t('gmtMontevideo') },
        { value: 'America/Noronha', text: this.$i18n.t('gmtNoronha') },
        { value: 'Atlantic/Cape_Verde', text: this.$i18n.t('gmtCape_Verde') },
        { value: 'Atlantic/Azores', text: this.$i18n.t('gmtAzores') },
        {
          value: 'Africa/Casablanca',
          text: this.$i18n.t('gmtCasablanca'),
        },
        {
          value: 'Etc/Greenwich',
          text: this.$i18n.t('gmtGreenwich'),
        },
        {
          value: 'Europe/Amsterdam',
          text: this.$i18n.t('gmtAmsterdam'),
        },
        {
          value: 'Europe/Belgrade',
          text: this.$i18n.t('gmtBelgrade'),
        },
        {
          value: 'Europe/Brussels',
          text: this.$i18n.t('gmtBrussels'),
        },
        {
          value: 'Europe/Sarajevo',
          text: this.$i18n.t('gmtSarajevo'),
        },
        { value: 'Africa/Lagos', text: this.$i18n.t('gmtLagos') },
        { value: 'Asia/Amman', text: this.$i18n.t('gmtAmman') },
        {
          value: 'Europe/Athens',
          text: this.$i18n.t('gmtAthens'),
        },
        { value: 'Asia/Beirut', text: this.$i18n.t('gmtBeirut') },
        { value: 'Africa/Cairo', text: this.$i18n.t('gmtCairo') },
        { value: 'Africa/Harare', text: this.$i18n.t('gmtHarare') },
        {
          value: 'Europe/Helsinki',
          text: this.$i18n.t('gmtHelsinki'),
        },
        { value: 'Asia/Jerusalem', text: this.$i18n.t('gmtJerusalem') },
        { value: 'Europe/Minsk', text: this.$i18n.t('gmtMinsk') },
        { value: 'Africa/Windhoek', text: this.$i18n.t('gmtWindhoek') },
        { value: 'Asia/Kuwait', text: this.$i18n.t('gmtKuwait') },
        { value: 'Asia/Tbilisi', text: this.$i18n.t('gmtTbilisi') },
        { value: 'Asia/Tehran', text: this.$i18n.t('gmtTehran') },
        { value: 'Asia/Muscat', text: this.$i18n.t('gmtMuscat') },
        { value: 'Asia/Baku', text: this.$i18n.t('gmtBaku') },
        { value: 'Asia/Yerevan', text: this.$i18n.t('gmtYerevan') },
        { value: 'Asia/Kabul', text: this.$i18n.t('gmtKabul') },
        { value: 'Asia/Yekaterinburg', text: this.$i18n.t('gmtYekaterinburg') },
        {
          value: 'Asia/Karachi',
          text: this.$i18n.t('gmtKarachi'),
        },
        { value: 'Asia/Calcutta', text: this.$i18n.t('gmtCalcutta') },
        {
          value: 'Asia/Calcutta',
          text: this.$i18n.t('gmtChennai'),
        },
        { value: 'Asia/Katmandu', text: this.$i18n.t('gmtKatmandu') },
        { value: 'Asia/Almaty', text: this.$i18n.t('gmtAlmaty') },
        { value: 'Asia/Dhaka', text: this.$i18n.t('gmtDhaka') },
        { value: 'Asia/Rangoon', text: this.$i18n.t('gmtRangoon') },
        { value: 'Asia/Bangkok', text: this.$i18n.t('gmtBangkok') },
        {
          value: 'Asia/Hong_Kong',
          text: this.$i18n.t('gmtHongkong'),
        },
        {
          value: 'Asia/Kuala_Lumpur',
          text: this.$i18n.t('gmtKualaLumpur'),
        },
        { value: 'Asia/Irkutsk', text: this.$i18n.t('gmtIrkutsk') },
        { value: 'Australia/Perth', text: this.$i18n.t('gmtPerth') },
        { value: 'Asia/Tokyo', text: this.$i18n.t('gmtTokyo') },
        { value: 'Asia/Seoul', text: this.$i18n.t('gmtSeoul') },
        { value: 'Asia/Yakutsk', text: this.$i18n.t('gmtYakutsk') },
        { value: 'Australia/Adelaide', text: this.$i18n.t('gmtAdelaide') },
        { value: 'Australia/Darwin', text: this.$i18n.t('gmtDarwin') },
        { value: 'Australia/Brisbane', text: this.$i18n.t('gmtBrisbane') },
        {
          value: 'Australia/Canberra',
          text: this.$i18n.t('gmtCanberra'),
        },
        { value: 'Australia/Hobart', text: this.$i18n.t('gmtHobart') },
        { value: 'Pacific/Guam', text: this.$i18n.t('gmtGuam') },
        { value: 'Asia/Vladivostok', text: this.$i18n.t('gmtVladivostok') },
        {
          value: 'Asia/Magadan',
          text: this.$i18n.t('gmtMagadan'),
        },
        { value: 'Pacific/Auckland', text: this.$i18n.t('gmtAuckland') },
        {
          value: 'Pacific/Fiji',
          text: this.$i18n.t('gmtFiji'),
        },
        { value: 'Pacific/Tongatapu', text: this.$i18n.t('gmtTongatapu') },
      ],
      browserTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
  },
  created(){
    this.checkAndAddBrowserTimezone()
  },
  computed: {
    ...mapGetters(['shops', 'shopsetting']),
    isPermission() {
      return permis.findPermissionRead('setting', '/setting/language')
    },
    isEditTimeZone() {
      return permis.findPermissionEdit('setting', '/setting/language')
    },
    datashop: {
      get() {
        return this.$store.getters.shopsetting
      },
      set(newValue) {
        return this.$store.dispatch('setShopSetting', newValue)
      },
    },
    timezone: {
      get() {
        return this.options.find(({ value }) => value === this.browserTimezone) || { value: this.browserTimezone, text: this.browserTimezone }
      },
      set(newValue) {
        this.browserTimezone = newValue.value
      },
    },
  },
  methods: {
    changeLocale(item) {
      // this.shopsetting.timezone = item.value
      // this.$store.dispatch('setNewSetting')
      this.updateOptions()
    },
    checkAndAddBrowserTimezone() {
      const found = this.options.some(option => option.value === this.browserTimezone);
      if (!found) {
        this.options.push({ value: this.browserTimezone, text: this.browserTimezone });
      }
    },
    updateOptions() {
      this.options = [
        {
          value: 'Etc/GMT+12',
          text: this.$i18n.t('gmtLinewest'),
        },
        { value: 'Pacific/Midway', text: this.$i18n.t('gmtMidway') },
        { value: 'Pacific/Honolulu', text: this.$i18n.t('gmtHonolulu') },
        { value: 'US/Alaska', text: this.$i18n.t('gmtAlaska') },
        {
          value: 'America/Los_Angeles',
          text: this.$i18n.t('gmtLos_Angeles'),
        },
        {
          value: 'America/Tijuana',
          text: this.$i18n.t('gmtTijuana'),
        },
        { value: 'US/Arizona', text: this.$i18n.t('gmtArizona') },
        {
          value: 'America/Chihuahua',
          text: this.$i18n.t('gmtChihuahua'),
        },
        {
          value: 'US/Mountain',
          text: this.$i18n.t('gmtMoutain'),
        },
        { value: 'America/Managua', text: this.$i18n.t('gmtManagua') },
        { value: 'US/Central', text: this.$i18n.t('gmtCentral') },
        {
          value: 'America/Mexico_City',
          text: this.$i18n.t('gmtMexico'),
        },
        { value: 'Canada/Saskatchewan', text: this.$i18n.t('gmtSaskatchewan') },
        {
          value: 'America/Bogota',
          text: this.$i18n.t('gmtBogota'),
        },
        { value: 'US/Eastern', text: this.$i18n.t('gmtEastern') },
        { value: 'US/East-Indiana', text: this.$i18n.t('gmtIndiana') },
        {
          value: 'Canada/Atlantic',
          text: this.$i18n.t('gmtAtlantic'),
        },
        { value: 'America/Caracas', text: this.$i18n.t('gmtCaracas') },
        { value: 'America/Manaus', text: this.$i18n.t('gmtManaus') },
        { value: 'America/Santiago', text: this.$i18n.t('gmtSantiago') },
        { value: 'Canada/Newfoundland', text: this.$i18n.t('gmtNewfoundland') },
        { value: 'America/Sao_Paulo', text: this.$i18n.t('gmtSao_Paulo') },
        {
          value: 'America/Argentina/Buenos_Aires',
          text: this.$i18n.t('gmtBuenos_Aires'),
        },
        { value: 'America/Godthab', text: this.$i18n.t('gmtGodthab') },
        { value: 'America/Montevideo', text: this.$i18n.t('gmtMontevideo') },
        { value: 'America/Noronha', text: this.$i18n.t('gmtNoronha') },
        { value: 'Atlantic/Cape_Verde', text: this.$i18n.t('gmtCape_Verde') },
        { value: 'Atlantic/Azores', text: this.$i18n.t('gmtAzores') },
        {
          value: 'Africa/Casablanca',
          text: this.$i18n.t('gmtCasablanca'),
        },
        {
          value: 'Etc/Greenwich',
          text: this.$i18n.t('gmtGreenwich'),
        },
        {
          value: 'Europe/Amsterdam',
          text: this.$i18n.t('gmtAmsterdam'),
        },
        {
          value: 'Europe/Berlin',
          text: this.$i18n.t('gmtAmsterdam'),
        },
        {
          value: 'Europe/Belgrade',
          text: this.$i18n.t('gmtBelgrade'),
        },
        {
          value: 'Europe/Brussels',
          text: this.$i18n.t('gmtBrussels'),
        },
        {
          value: 'Europe/Sarajevo',
          text: this.$i18n.t('gmtSarajevo'),
        },
        { value: 'Africa/Lagos', text: this.$i18n.t('gmtLagos') },
        { value: 'Asia/Amman', text: this.$i18n.t('gmtAmman') },
        {
          value: 'Europe/Athens',
          text: this.$i18n.t('gmtAthens'),
        },
        { value: 'Asia/Beirut', text: this.$i18n.t('gmtBeirut') },
        { value: 'Africa/Cairo', text: this.$i18n.t('gmtCairo') },
        { value: 'Africa/Harare', text: this.$i18n.t('gmtHarare') },
        {
          value: 'Europe/Helsinki',
          text: this.$i18n.t('gmtHelsinki'),
        },
        { value: 'Asia/Jerusalem', text: this.$i18n.t('gmtJerusalem') },
        { value: 'Europe/Minsk', text: this.$i18n.t('gmtMinsk') },
        { value: 'Africa/Windhoek', text: this.$i18n.t('gmtWindhoek') },
        { value: 'Asia/Kuwait', text: this.$i18n.t('gmtKuwait') },
        { value: 'Asia/Tbilisi', text: this.$i18n.t('gmtTbilisi') },
        { value: 'Asia/Tehran', text: this.$i18n.t('gmtTehran') },
        { value: 'Asia/Muscat', text: this.$i18n.t('gmtMuscat') },
        { value: 'Asia/Baku', text: this.$i18n.t('gmtBaku') },
        { value: 'Asia/Yerevan', text: this.$i18n.t('gmtYerevan') },
        { value: 'Asia/Kabul', text: this.$i18n.t('gmtKabul') },
        { value: 'Asia/Yekaterinburg', text: this.$i18n.t('gmtYekaterinburg') },
        {
          value: 'Asia/Karachi',
          text: this.$i18n.t('gmtKarachi'),
        },
        { value: 'Asia/Calcutta', text: this.$i18n.t('gmtCalcutta') },
        {
          value: 'Asia/Calcutta',
          text: this.$i18n.t('gmtChennai'),
        },
        { value: 'Asia/Katmandu', text: this.$i18n.t('gmtKatmandu') },
        { value: 'Asia/Almaty', text: this.$i18n.t('gmtAlmaty') },
        { value: 'Asia/Dhaka', text: this.$i18n.t('gmtDhaka') },
        { value: 'Asia/Rangoon', text: this.$i18n.t('gmtRangoon') },
        { value: 'Asia/Bangkok', text: this.$i18n.t('gmtBangkok') },
        {
          value: 'Asia/Hong_Kong',
          text: this.$i18n.t('gmtHongkong'),
        },
        {
          value: 'Asia/Kuala_Lumpur',
          text: this.$i18n.t('gmtKualaLumpu'),
        },
        { value: 'Asia/Irkutsk', text: this.$i18n.t('gmtIrkutsk') },
        { value: 'Australia/Perth', text: this.$i18n.t('gmtPerth') },
        { value: 'Asia/Tokyo', text: this.$i18n.t('gmtTokyo') },
        { value: 'Asia/Seoul', text: this.$i18n.t('gmtSeoul') },
        { value: 'Asia/Yakutsk', text: this.$i18n.t('gmtYakutsk') },
        { value: 'Australia/Adelaide', text: this.$i18n.t('gmtAdelaide') },
        { value: 'Australia/Darwin', text: this.$i18n.t('gmtDarwin') },
        { value: 'Australia/Brisbane', text: this.$i18n.t('gmtBrisbane') },
        {
          value: 'Australia/Canberra',
          text: this.$i18n.t('gmtCanberra'),
        },
        { value: 'Australia/Hobart', text: this.$i18n.t('gmtHobart') },
        { value: 'Pacific/Guam', text: this.$i18n.t('gmtGuam') },
        { value: 'Asia/Vladivostok', text: this.$i18n.t('gmtVladivostok') },
        {
          value: 'Asia/Magadan',
          text: this.$i18n.t('gmtMagadan'),
        },
        { value: 'Pacific/Auckland', text: this.$i18n.t('gmtAuckland') },
        {
          value: 'Pacific/Fiji',
          text: this.$i18n.t('gmtFiji'),
        },
        { value: 'Pacific/Tongatapu', text: this.$i18n.t('gmtTongatapu') },
      ]
    },
  },


  watch: {
    '$i18n.locale': function (newLocale, oldLocale) {
      this.updateOptions()
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
