<template>
  <div v-if="isPermission">
    <CRow class="mt-3">
      <CCol md="12" class="text-center">
        <h2 class="font-weight-medium text-dark">{{ $t('language') }}</h2>
        <h6 class="text-description">
          {{ $t('lansetting') }}
        </h6>
      </CCol>
    </CRow>
    <hr />
    
    <table style="width:100%" class="mt-5">
      <tr>
        <td style="width:10%" valign="center">
          <h6 class="font-weight-normal mt-2">{{ $t('languagetxt') }}</h6>
        </td>
        <td>
          <div v-if="isEditLanguage == true">
            <div class="row">
              <div class="col-6">
                <CButton
                  v-if="$i18n.locale === 'th'"
                  active
                  size="lg"
                  style=" width: 100%;"
                  class="btn-outline-success p-3 text-left"
                  @click="changeLocale('th')"
                  id="languageChangeToThai"
                >
                  <span>
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/330/330447.png"
                      alt="..."
                      width="15%"
                      class="img-fluid"
                    />
                    <small class="text-dark pl-3">{{ $t('languageTH') }}</small>
                  </span>
                </CButton>
                <CButton
                  v-else
                  active
                  size="lg"
                  style=" width: 100%;"
                  class="btn-outline-light p-3 text-left"
                  @click="changeLocale('th')"
                  id="languageChangeToThaiInactive"
                >
                  <span>
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/330/330447.png"
                      alt="..."
                      width="15%"
                      class="img-fluid"
                    />
                    <small class="text-dark pl-3">{{ $t('languageTH') }}</small>
                  </span>
                </CButton>
              </div>
              <div class="col-6">
                <CButton
                  v-if="$i18n.locale === 'en'"
                  size="lg"
                  style="width: 100%;"
                  class="btn-outline-success p-3 text-left"
                  @click="changeLocale('en')"
                  id="languageChangeToEnglish"
                >
                  <span>
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/330/330459.png"
                      width="15%"
                      class="img-fluid"
                    />
                    <small class="text-dark pl-3"
                      >{{ $t('languageEN') }}
                    </small>
                  </span>
                </CButton>
                <CButton
                  v-else
                  size="lg"
                  style="width: 100%;"
                  class="btn-outline-light p-3 text-left"
                  @click="changeLocale('en')"
                  id="languageChangeToEnglishInactive"
                >
                  <span>
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/330/330459.png"
                      width="15%"
                      class="img-fluid"
                    />
                    <small class="text-dark pl-3"
                      >{{ $t('languageEN') }}
                    </small>
                  </span>
                </CButton>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row">
              <div class="col-6">
                <CButton
                  v-if="$i18n.locale === 'th'"
                  active
                  size="lg"
                  style=" width: 100%;"
                  class="btn-outline-success p-3 text-left"
                  disabled
                  id="languageThaiDisabled"
                >
                  <span>
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/330/330447.png"
                      alt="..."
                      width="15%"
                      class="img-fluid"
                    />
                    <small class="text-dark pl-3">{{ $t('languageTH') }}</small>
                  </span>
                </CButton>
                <CButton
                  v-else
                  active
                  size="lg"
                  style=" width: 100%;"
                  class="btn-outline-light p-3 text-left"
                  disabled
                  id="languageThaiInactiveDisabled"
                >
                  <span>
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/330/330447.png"
                      alt="..."
                      width="15%"
                      class="img-fluid"
                    />
                    <small class="text-dark pl-3">{{ $t('languageTH') }}</small>
                  </span>
                </CButton>
              </div>
              <div class="col-6">
                <CButton
                  v-if="$i18n.locale === 'en'"
                  size="lg"
                  style="width: 100%;"
                  class="btn-outline-success p-3 text-left"
                  disabled
                  id="languageEnglishDisabled"
                >
                  <span>
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/330/330459.png"
                      width="15%"
                      class="img-fluid"
                    />
                    <small class="text-dark pl-3">
                      {{ $t('languageEN') }}
                    </small>
                  </span>
                </CButton>
                <CButton
                  v-else
                  size="lg"
                  style="width: 100%;"
                  class="btn-outline-light p-3 text-left"
                  disabled
                  id="languageEnglishInactiveDisabled"
                >
                  <span>
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/330/330459.png"
                      width="15%"
                      class="img-fluid"
                    />
                    <small class="text-dark pl-3">
                      {{ $t('languageEN') }}
                    </small>
                  </span>
                </CButton>  
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr style="height: 50px"></tr>
      <tr>
        <td valign="center">
          <h6 class="font-weight-normal mt-2">
            {{ $t('timezone') }}
          </h6>
        </td>
        <td>
          <timezone id="languageTimezone"></timezone>
        </td>
      </tr>
    </table>
  </div>
  <div v-else>
    <access-data  id="languageAccessData"></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import i18n from '@/plugins/i18n'
import pos from '@/services/local'
import permis from '@/util/permission'
import Timezone from './TimeZone'

export default {
  components: {
    Timezone,
  },
  data() {
    return {
      menuLanguage: false,
    }
  },
  computed: {
    ...mapGetters(['shops', 'shopsetting']),
    flagBtn() {
      return this.datashop.language
    },
    datashop: {
      get() {
        return this.$store.getters.shopsetting
      },
      set(newValue) {
        return this.$store.dispatch('setShopSetting', newValue)
      },
    },
    isPermission() {
      return permis.findPermissionRead('setting', this.$route.path)
    },
    isEditLanguage() {
      return permis.findPermissionEdit('setting', this.$route.path)
    },
  },
  methods: {
    changeLocale(locale) {
      this.datashop.language = locale
      i18n.locale = locale
      const uid = `${localStorage.getItem('shopsUid')}`
      this.loading = true
      this.isSuccess = false
      let shops = this.shops
      for (let i = 0; i < shops.length; i++) {
        shops[i].language = locale
        pos({
          method: 'post',
          url: '/api/v1.0/' + uid + '/Shop/updateconfig',
          data: shops[i],
        })
          .then(() => {
            setTimeout(() => {}, 1000)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
  },
}
</script>
